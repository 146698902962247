<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A sample of a theoretical radioactive material has a half-life of
        <number-value :value="halfLife" /> years. What percentage of this material will be left
        after <number-value :value="time" /> years?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{Sample Remaining}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'ChemSIU131_Task3',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    halfLife() {
      return this.taskState.getValueBySymbol('halfLife').content;
    },
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
  },
};
</script>
